import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-accessibility-reading-mask',
  templateUrl: './accessibility-reading-mask.component.html',
  styleUrls: ['./accessibility-reading-mask.component.scss']
})
export class AccessibilityReadingMaskComponent {

  public topHeigh = "0px";

  @HostListener("window:mousemove", ["$event"])
  onMouseMove(event:any) {
    this.topHeigh = (event.clientY - 10 )+ 'px';
  }
}
