import {faCircleHalfStroke} from "@fortawesome/free-solid-svg-icons";
import FunctionInterface from "../contracts/FunctionInterface";
import BaseFunctionality from "../contracts/BaseFunctionality";

class GrayScale extends  BaseFunctionality implements FunctionInterface{

  public static itemName = "Escala de grises";
  public static description = "Escala de grises";
  public static icon = faCircleHalfStroke;
  public static extraCssClases = "";

  public affectedElements = ["img", "div"]
  public cssClassName = "grayscale";


  public activate() {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
    });
  }

  public deactivate() {
    this.getElements().forEach(element => {
      element.classList.remove(this.cssClassName)
    });
  }
}

export default GrayScale;
