export default class BaseFunctionality {

  // Elemento que concentra todos los elementos a
  // los que se les van aplicar las funciones
  // definido en el servicio
  public parent: Element = {} as Element;


  // Elementos que van a ser afectados por la
  // funcion correspondiente. Debe ser sobreescrita
  public affectedElements: Array<any> =[];


  // Nombre de la clase css a de la funcion
  // correspondiente. Debe ser sobreescrita
  public cssClassName: string='';


  // Se sobrescribe como <false> en caso de que
  // la funcionalidad no requiera el padre
  public needParent: boolean = true;


  // Se sobrescribe como <true> en caso de que
  // la funcionalidad SI contenga un componente
  public hasComponent: boolean = false;


  // Se sobrescribe como <true> en caso de que
  // la funcionalidad SI contenga un componente
  // y este deba mostrarse
  public componentShow: boolean = false;


  // Se sobrescribe con el nombre del componente
  // previamente declarado en el componente principal
  // de accesibilidad y que se deba conectar con
  // la funcionalidad correspondiente
  public componentName: string = "";


  // Se sobrescribe como <true> en caso de que
  // la funcionalidad requiera de botones de
  // control + y - (como el espaciado)
  public controlButtons: boolean = false;


  // Se sobrescribe entero en para mover
  // los valores de la funcionalidad
  public controlNumber: number = 1;


  // Para establecer el elemento padre, cuando
  // la vista ya fue renderizada
  public setParent(parent: Element) {
    /* console.log('BaseFunctionality::setParent()')
    console.log(parent) */
    this.parent = parent;
  }


  // Obtener los elementos que se van afectar
  // obtenidos del padre, o en su defecto,
  // los obtiene del documento.
  protected getElements() {
    /* console.log('BaseFunctionality::getElements()')
    console.log('this.parent')
    console.log(this.parent) */

    let allElements: Array<any> = [];
    this.affectedElements.forEach(elementType => {
      let elements = this.needParent && this.parent
        ? this.parent.getElementsByTagName(elementType)
        : document.getElementsByTagName(elementType)
      // @ts-ignore
      allElements.push(...elements)
    })
    /* console.log('allElements')
    console.log(allElements) */
    return allElements;
  }
}
