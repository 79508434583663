import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-directorio-telefonico',
  templateUrl: './directorio-telefonico.component.html',
  styleUrls: ['./directorio-telefonico.component.css']
})

export class DirectorioTelefonicoComponent implements OnInit {
  directorio: any[] = [];
  currentItemsToShow:any[] = [];
  aviso:string = '';
  buscar:string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getDirectorio(global.url_controller);
  }

  ngOnInit() { }

  onPageChange($event:any) {
    this.currentItemsToShow =  this.directorio.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getDirectorio(url_controller:any) {
    this.http.get(url_controller+'directorio?buscar='+this.buscar)
    .subscribe( (result: any) => {
      this.directorio = result;
      if (this.directorio.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.directorio.slice(0, 20);
    });
  }

  search(search:any)
  {
    this.http.get('http://localhost/rest_webgdl/index.php/api/directorio?buscar='+search)
    .subscribe( (result: any) => {
      this.directorio = result;
      if (this.directorio.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.directorio.slice(0, 20);
    });
  }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

  linkFormat(link:any) {
    const b = link.split(' ').join('-');
    const linkDirectorioFormat1 = this.slugify(link);
    const linkDirectorioFormat = linkDirectorioFormat1.replace(/[^a-z0-9-]/gi, '-');
    return linkDirectorioFormat;
  }

  slugify(str:any) {
    const map = [
        '-_ ',
        'a_á|à|ã|â|À|Á|Ã|Â',
        'e_é|è|ê|É|È|Ê',
        'i_í|ì|î|Í|Ì|Î',
        'o_ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u_ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c_ç|Ç',
        'n_ñ|Ñ'
    ];
    str = str.toLowerCase();
    // tslint:disable-next-line:forin
    for (const pattern in map) {
        let arr=pattern.split('_')
        str = str.replace(new RegExp(arr[1], 'g'), arr[0]);
    }

    return str;
}
}
