import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
menu:any;
http: HttpClient ={} as HttpClient;
  constructor(public http2: HttpClient,public router: Router) {
  }

  ngOnInit() {

  }

  menuNavigate(routeString:string){
    console.log('ruta: ',routeString)
    console.log(this.router.navigate([routeString]))
  }
}
