import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-regidores',
  templateUrl: './regidores.component.html',
  styleUrls: ['./regidores.component.css']
})

export class RegidoresComponent implements OnInit {
  regidores: any[] = [];
  sesiones: any[] = [];
  currentItemsToShow1:any[] = [];
  currentItemsToShow2:any[] = [];
  aviso = '';
  link = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getRegidores(global.url_controller);
    this.getSesiones(global.url_controller);
  }

  ngOnInit() {}

  onPageChange($event:any) {
    this.currentItemsToShow1 =  this.regidores.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getRegidores(url_controller:any) {
    this.http.get(url_controller+'regidores')
    .subscribe( (result: any) => {
      this.regidores = result;
      if (this.regidores.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow1 = this.regidores.slice(0, 20);
      //console.log(this.currentItemsToShow1);
    });
  }

  getSesiones(url_controller:any) {
    this.http.get(url_controller+'videos')
    .subscribe( (result: any) => {
      this.sesiones = result;
      if (this.sesiones.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow2 = this.sesiones.slice(0, 20);
      //console.log(this.currentItemsToShow2);

      this.link = this.currentItemsToShow2[0]['link_video'];
      console.log(this.link);
    });
  }

  verComisiones() {
    window.open("http://regidoresencontacto.guadalajara.gob.mx/comisiones", "_blank");
  }
}
