import BaseFunctionality from "../contracts/BaseFunctionality";
import FunctionInterface from "../contracts/FunctionInterface";
import {faArrowsUpDown} from "@fortawesome/free-solid-svg-icons";

class VerticalSpacing extends BaseFunctionality implements FunctionInterface {

  static itemName = "Espaciado vertical";
  static description = "Espaciado vertical";
  static icon = faArrowsUpDown;
  public static extraCssClases = "";

  controlButtons = true;
  controlNumber = 25;
  affectedElements = [
    "p", "span", "button", "h1",
    "h2", "h3", "h4", "h5", "h6",
    "a", "li",
  ]

  minus() {
    this.controlNumber -= 1;
  }

  plus() {
    this.controlNumber += 1;
  }

  activate(): void {
    this.getElements().forEach(element => {
      element.style.lineHeight = this.controlNumber + "px";
    });
  }

  deactivate(): void {
    this.getElements().forEach(element => {
      element.style.lineHeight = "inherit";
    });
  }

}

export default VerticalSpacing;
