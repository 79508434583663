import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-comisaria',
  templateUrl: './comisaria.component.html',
  styleUrls: ['./comisaria.component.css']
})
export class ComisariaComponent implements OnInit {

  comisarias: any[] = [];

  constructor(private http: HttpClient) {
    this.http.get('https://api2.guadalajara.gob.mx/gdl/ApiComisaria/')
    .subscribe( (comisarias: any) => {
      this.comisarias = comisarias;
      console.log(comisarias);
    });
   }

  ngOnInit() {
  }

}
