import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-gerencia-detalle',
  templateUrl: './gerencia-detalle.component.html',
  styleUrls: ['./gerencia-detalle.component.css']
})

export class GerenciaDetalleComponent implements OnInit { 
  idDirectorio;
  linkPersonal;
  idPublicacion:any;
  personal: any[] = [];
  currentItemsToShow:any[] = [];
  catElement: any[] = [];
  catValue = 0;
  aviso = '';
  colonia: any[] = [];
  arrayColonias:any[]  = [];

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private route: ActivatedRoute, private http: HttpClient, public global: AppComponent) {
    this.idDirectorio = this.route.snapshot.paramMap.get('id');
    this.linkPersonal = this.route.snapshot.paramMap.get('link');
    this.getManagementDetail(global.url_controller);
    //this.getColonias(global.url_controller);
  }

  onPageChange($event:any) { }

  ngOnInit() { }

  getManagementDetail(url_controller:any) {
    //this.http.get(url_controller+'directorio_detalle?id_directorio='+this.idDirectorio)
    this.http.get(url_controller+'directorio_detalle?id_link='+this.linkPersonal)
    .subscribe( (result: any) => {
      this.personal = result;
      if (this.personal.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.personal.slice(0, 20);
      this.idPublicacion = this.currentItemsToShow[0]['id_publicacion'];

      this.http.get(url_controller+'colonias?id_directorio='+this.idPublicacion)
      .subscribe( (result: any) => {
        this.colonia = result;
        if (this.colonia.length === 0 ) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.arrayColonias = this.colonia.slice(0, 20);
        console.log(this.arrayColonias);
      });

      //console.log(this.idPublicacion);
    });
  }

  /*getColonias(url_controller) {
    this.http.get(url_controller+'colonias?id_directorio='+this.idDirectorio)
    .subscribe( (result: any) => {
      this.colonia = result;
      if (this.colonia.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.arrayColonias = this.colonia.slice(0, 20);
      console.log(this.arrayColonias);
    });
  }*/

  setPage(size: number) { }

  linkFormat(link:any) {
    const b = link.split('-').join(' ');
    const linkPersonaFormat = b.replace(/[^áéíóúa-z0-9-]/gi, ' ');
    return linkPersonaFormat;
  }
}
