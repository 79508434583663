import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.css']
})
export class AvisoPrivacidadComponent implements OnInit {
  fileUrl = 'https://guadalajara.gob.mx/aviso-privacidad.pdf'
  constructor() { }

  ngOnInit(): void {
  }

}
