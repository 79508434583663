import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-coordinacion-dependencias',
  templateUrl: './coordinacion-dependencias.component.html',
  styleUrls: ['./coordinacion-dependencias.component.css']
})

export class CoordinacionDependenciasComponent implements OnInit {
  dependencias: any[] = [];
  coordinaciones: any[] = [];
  currentItemsToShow1:any[] = [];
  currentItemsToShow2:any[] = [];
  aviso:string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient, public global: AppComponent) {
    this.getDependencias(global.url_controller);
    this.getCoordinaciones(global.url_controller);
  }

  ngOnInit() { }

  getCoordinaciones(url_controller:any) {
    this.http.get(url_controller+'coordinaciones')
    .subscribe( (result: any) => {
      this.coordinaciones = result;
      if (this.coordinaciones.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow1 = this.coordinaciones.slice(0, 20);
    });
  }

  getDependencias(url_controller:any) {
    this.http.get(url_controller+'dependencias')
    .subscribe( (result: any) => {
      this.dependencias = result;
      if (this.dependencias.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow2 = this.dependencias.slice(0, 20);
    });
  }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

  linkFormat(link:any) {
    const b = link.split(' ').join('-');
    const linkGerenciaFormat1 = this.slugify(link);
    const linkGerenciaFormat = linkGerenciaFormat1.replace(/[^a-z0-9-]/gi, '-');
    return linkGerenciaFormat;
  }

  slugify(str:any) {
    const map = [
        '-_ ',
        'a_á|à|ã|â|À|Á|Ã|Â',
        'e_é|è|ê|É|È|Ê',
        'i_í|ì|î|Í|Ì|Î',
        'o_ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u_ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c_ç|Ç',
        'n_ñ|Ñ'
    ];
    str = str.toLowerCase();
    // tslint:disable-next-line:forin
    for (const pattern in map) {
        let arr=pattern.split('_')
        str = str.replace(new RegExp(arr[1], 'g'), arr[0]);
    }

    return str;
}
}
