import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { DateTimeFormatOptions } from 'src/app/models/DateTimeFormatOptions';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})

export class NoticiasComponent implements OnInit, AfterViewInit {
  noticias: any[] = [];
  currentItemsToShow:any[] = [];
  catElement: any[] = [];
  catValue = 0;
  aviso = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;
  @ViewChild(MatSelect) matSelect: MatSelect = {} as MatSelect;

  ngOnInit() {
  }

  onPageChange($event:any) {
    this.currentItemsToShow =  this.noticias.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }


  constructor(private route: ActivatedRoute, private http: HttpClient, public global: AppComponent) {
    this.getNews(global.url_controller);
    this.getCat(global.url_controller);
   }

   setPage(size: number) {
     this.paginator.pageSize = size;
   }

  getCat(url_controller:any) {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/catalogos')
    .subscribe( (result: any) => {
      this.catElement = result;
      console.log(result);
    });
  }

  getNews(url_controller:any) {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/item?id_tipo=0')
    .subscribe( (result: any) => {
      if (result.status) {
        this.noticias = result[0];
        if (this.noticias.length === 0 ) {
          this.aviso = 'No se encontraron resultados';
        } else {
          this.aviso = '';
        }
        this.currentItemsToShow = this.noticias.slice(0, 20);
      } else {
        window.location.href = '#/notfound';
      }
    });
  }

  ngAfterViewInit() {}

  public fecha(fecha: string) {
    console.log("Esta es la fecha");
    const newDate = new Date(fecha);
    newDate.setDate(newDate.getDate() + 1);
    console.log(newDate);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    console.log(newDate.toLocaleString('es', options));
    return newDate.toLocaleString('es', options);
  }

  cuerpo(text: string) {
    var cuerpo = jQuery(text).text();
    cuerpo = cuerpo.substring(0, 230) + '...';

    return cuerpo.trim();
  }
}