import BaseFunctionality from "../contracts/BaseFunctionality";
import FunctionInterface from "../contracts/FunctionInterface";
import {faMarker} from "@fortawesome/free-solid-svg-icons";

class HighlightLinks extends BaseFunctionality implements FunctionInterface {

  static itemName = "Resaltar enlaces";
  static description = "Resaltar enlaces";
  static icon = faMarker;
  public static extraCssClases = "";

  affectedElements = ["a"]
  cssClassName = "highlight-links"


  public activate() {
    this.getElements().forEach(element => {
      element.classList.add(this.cssClassName)
    });
  }

  public deactivate() {
    this.getElements().forEach(element => {
      element.classList.remove(this.cssClassName)
    });
  }

}

export default HighlightLinks;
