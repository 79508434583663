import { Routes } from '@angular/router';
import { ComisariaComponent } from './components/comisaria/comisaria.component';
import { HistoriaComponent } from './components/historia/historia.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { DetalleNoticiaComponent } from './components/detalle-noticia/detalle-noticia.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BolsaTrabajoComponent } from './components/bolsa-trabajo/bolsa-trabajo.component';
import { BolsaTrabajoDetalleComponent } from './components/bolsa-trabajo-detalle/bolsa-trabajo-detalle.component';
import { ConvocatoriasComponent } from './components/convocatorias/convocatorias.component';
import { GerenciaComponent } from './components/gerencia/gerencia.component';
import { GerenciaDetalleComponent } from './components/gerencia-detalle/gerencia-detalle.component';
import { DirectorioTelefonicoComponent } from './components/directorio-telefonico/directorio-telefonico.component';
import { DirectorioTelefonicoDetalleComponent } from './components/directorio-telefonico-detalle/directorio-telefonico-detalle.component';
import { CoordinacionDependenciasComponent } from './components/coordinacion-dependencias/coordinacion-dependencias.component';
import { TramitesComponent } from './components/tramites/tramites.component';
import { CoordinacionesComponent } from './components/coordinaciones/coordinaciones.component';
import { RegidoresComponent } from './components/regidores/regidores.component';
import { RegidoresContactoComponent } from './components/regidores-contacto/regidores-contacto.component';
import { ComunicacionInstitucionalComponent } from './components/comunicacion-institucional/comunicacion-institucional.component';
import { GobiernoComponent } from './components/gobierno/gobierno.component';
import { SesionAyuntamientoComponent } from './components/sesion-ayuntamiento/sesion-ayuntamiento.component';
import { ParquesJardinesComponent } from './components/parques-jardines/parques-jardines.component';
import { OrganigramaComponent } from './components/organigrama/organigrama.component';
import { OpdComponent } from './components/opd/opd.component';
import { ConoceComponent } from './components/conoce/conoce.component';
import { TesomovilComponent } from './components/tesomovil/tesomovil.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { ComunicadosComponent } from './components/comunicados/comunicados.component';
import { AvisoPrivacidadComponent } from './components/aviso-privacidad/aviso-privacidad.component';

export const ROUTES: Routes = [
  { path: 'principal', component: PrincipalComponent },
  { path: 'aviso-de-privacidad', component: AvisoPrivacidadComponent },
  { path: 'comisaria', component: ComisariaComponent },
  { path: 'historia', component: HistoriaComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'noticias/convocatorias', component: ConvocatoriasComponent },
  { path: 'detalle/:id/:link', component: DetalleNoticiaComponent },
  { path: 'notfound', component: NotFoundComponent },
  { path: 'bolsa-de-trabajo', component: BolsaTrabajoComponent },
  { path: 'organismos-publicos-descentralizados', component: OpdComponent },
  { path: 'bolsa-de-trabajo-detalle/:id/:link', component: BolsaTrabajoDetalleComponent },
  { path: 'conoce-al-presidente-municipal', component: ConoceComponent },
  { path: 'conoce-a-la-presidenta-municipal', component: ConoceComponent },
  { path: 'conoce-a-la-presidenta-municipal-interina', component: ConoceComponent },
  // { path: 'conoce-al-presidente-municipal-suplente', component: ConoceComponent },
  { path: 'gerencia', component: GerenciaComponent },
  //{path: 'gerencia-detalle/:id/:link', component: GerenciaDetalleComponent},
  { path: 'gerencia-detalle/:link', component: GerenciaDetalleComponent },
  { path: 'directorio-telefonico', component: DirectorioTelefonicoComponent },
  //{path: 'directorio-telefonico-detalle/:id/:link', component: DirectorioTelefonicoDetalleComponent},
  { path: 'directorio-telefonico-detalle/:link', component: DirectorioTelefonicoDetalleComponent },
  { path: 'coordinacion-dependencias', component: CoordinacionDependenciasComponent },
  { path: 'tramites', component: TramitesComponent },
  { path: 'coordinaciones/:link', component: CoordinacionesComponent },
  { path: 'comunicacion-institucional', component: ComunicacionInstitucionalComponent },
  { path: 'comunicacion-institucional/comunicados', component: ComunicadosComponent },
  { path: 'regidores', component: RegidoresComponent },
  { path: 'regidores-contacto', component: RegidoresContactoComponent },
  { path: 'gobierno', component: GobiernoComponent },
  { path: 'sesion-de-ayuntamiento', component: SesionAyuntamientoComponent },
  { path: 'organigrama', component: OrganigramaComponent },
  { path: 'parques-y-jardines', component: ParquesJardinesComponent },
  { path: 'tesomovil', component: TesomovilComponent },
  { path: '', pathMatch: 'full', redirectTo: 'principal' },
  { path: '**', pathMatch: 'full', redirectTo: 'notfound' }
];
