import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-directorio-telefonico-detalle',
  templateUrl: './directorio-telefonico-detalle.component.html',
  styleUrls: ['./directorio-telefonico-detalle.component.css']
})

export class DirectorioTelefonicoDetalleComponent implements OnInit {
  idDirectorio;
  linkPersonal;
  personal: any[] = [];
  currentItemsToShow:any[] = [];
  catElement: any[] = [];
  catValue = 0;
  aviso = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private route: ActivatedRoute, private http: HttpClient, public global: AppComponent) {
    this.idDirectorio = this.route.snapshot.paramMap.get('id');
    this.linkPersonal = this.route.snapshot.paramMap.get('link');
    this.getDirectorioDetail(global.url_controller);
  }

  onPageChange($event:any) {}

  ngOnInit() {
  }

  getDirectorioDetail(url_controller:any) {
    //this.http.get(url_controller+'directorio_detalle?id_directorio='+this.idDirectorio)
    this.http.get(url_controller+'directorio_detalle?id_link='+this.linkPersonal)
    .subscribe( (result: any) => {
      this.personal = result;
      if (this.personal.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.personal.slice(0, 20);
      console.log(this.currentItemsToShow);
    });
  }

  setPage(size: number) {}

  linkFormat(link:any) {
    const b = link.split('-').join(' ');
    const linkPersonaFormat = b.replace(/[^áéíóúa-z0-9-]/gi, ' ');
    return linkPersonaFormat;
  }
}
