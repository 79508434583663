import {Component, NgModule, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {AccessibilityService} from "./accessibility.service";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      
      [
        transition(
          ':enter',
          [
            style({left: "-15vw", opacity: 0}),
            animate('0.35s ease-out',
              style({left: '0', opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({left: '0', opacity: 1}),
            animate('0.35s ease-in',
              style({left: "-15vw", opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class AccessibilityComponent implements OnInit {

  public icons = {
    mas: faPlus,
    menos: faMinus
  }


  constructor(private service: AccessibilityService) {
  }

  async ngOnInit() {
    setTimeout(() => {
       this.service.onInit()
      this.menuOptions = this.service.options;
    }, 1000)
   
  }

  public showMenu: boolean = false;

  public menuOptions: Array<any> = []

  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  public getComponentsFunction() {
    return this.menuOptions.filter(option => {
      return option.componentName
    })
  }

  public showComponentOption(componentName: string) {
    let option = this.getComponentsFunction().find(option =>
      option.componentName === componentName
    )
    return option ? option.active : false;
  }

  public change(option: any) {
    this.service.changeStatusOption(option)
  }

  public minus(option: any) {
    this.service.changeControlNumber(option, "minus")
  }

  public plus(option: any) {
    this.service.changeControlNumber(option, "plus")
  }
}
