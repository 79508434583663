import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { DateTimeFormatOptions } from '../../models/DateTimeFormatOptions';
import * as jQuery from 'jquery' ;

@Component({
  selector: 'app-bolsa-trabajo',
  templateUrl: './bolsa-trabajo.component.html',
  styleUrls: ['./bolsa-trabajo.component.css']
})
export class BolsaTrabajoComponent implements OnInit {
  trabajos: any[] = [];
  currentItemsToShow : any[]= [];
  catElement: any[] = [];
  catValue = 0;
  aviso = '';

  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(private http: HttpClient) {
    this.getJobs();
   }

  ngOnInit() {
  }

  onPageChange($event:any) {
    // tslint:disable-next-line:whitespace
    this.currentItemsToShow =  this.trabajos.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  getJobs() {
    this.http.get('https://aytoapi.guadalajara.gob.mx/rest/index.php/api/bolsa')
    .subscribe( (result: any) => {
      this.trabajos = result;
      if (this.trabajos.length === 0 ) {
        this.aviso = 'No se encontraron resultados';
      } else {
        this.aviso = '';
      }
      this.currentItemsToShow = this.trabajos.slice(0, 20);
      console.log(result);
    });
  }

  setPage(size: number) {
    this.paginator.pageSize = size;
  }

  public fecha(fecha: string) {
    const newDate = new Date(fecha);
    const options:DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    console.log(newDate.toLocaleString('es', options));
    return newDate.toLocaleString('es', options);
  }

  cuerpo(text: string) {
    const cuerpo = jQuery(text).text();
    return cuerpo.substring(0, 300) + '...';
  }

  genero(genero:any) {
    let generoStr = '';
    switch ( genero ) {
      case 'I':
        generoStr = 'INDISTINTO';
        return generoStr;
      case 'F':
        generoStr = 'FEMENINO';
        return generoStr;
      case 'M':
        generoStr = 'MASCULINO';
        return generoStr;
      default:
        generoStr = 'NO ESPECIFICADO';
        return generoStr;
    }
  }

  linkFormat(link:any) {
    const b = link.split(' ').join('-');
    const linkEmpresaFormat1 = this.slugify(link);
    const linkEmpresaFormat = linkEmpresaFormat1.replace(/[^a-z0-9-]/gi, '-');
    return linkEmpresaFormat;
  }

slugify(str:any) {
    const map = [
        '-_ ',
        'a_á|à|ã|â|À|Á|Ã|Â',
        'e_é|è|ê|É|È|Ê',
        'i_í|ì|î|Í|Ì|Î',
        'o_ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u_ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c_ç|Ç',
        'n_ñ|Ñ'
    ];
    str = str.toLowerCase();
    // tslint:disable-next-line:forin
    for (const pattern in map) {
        let arr=pattern.split('_')
        str = str.replace(new RegExp(arr[1], 'g'), arr[0]);
    }

    return str;
}

}
